import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/EvsCca/picture/1.jpg';
import p2 from 'assests/Photos/Clubs/EvsCca/picture/2.jpg';
import p3 from 'assests/Photos/Clubs/EvsCca/picture/3.jpg';
import p4 from 'assests/Photos/Clubs/EvsCca/picture/4.jpg';
import SideBarSci from 'views/ScienceClub/SideBarSci';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Scienceclubsidebar2023 from 'views/ScienceClub/Scienceclubsidebar2023';
import ScienceClubSideBar from 'views/ScienceClubSideBar/ScienceClubSideBar';


const PictureTalk2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 2,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 2,
        },

    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                    Picture Talk & Model Making
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 1 & 2  &  Date: 24 JULY 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The students of Classes 1 and 2 participated in the EVS Inter-House CCA Competition conducted on Monday, 24 July 2023.
                                    <br></br>
                                    Students of Class 1 spoke about body parts and demonstrated their understanding with the help of props while the students of Class 2 showcased their creativity by crafting life like models of internal and external organs.
                                    <br></br>
                                    Both students of Classes 1 and 2 presented interesting facts and information about their models and shared inputs on how to take care of the organs in order to lead a healthy life.
                                    <br></br>
                                    The viewers were mesmerized by their creative work and communication skills.
                                    <br></br>
                                    These activities not only expanded their knowledge but also honed their communication and artistic skills and helped them become confident speakers to face the real world in the coming future.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "We are here to Express, Impress and Inform"

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Scienceclubsidebar2023 />
                        </Box>
                        <Box marginBottom={4}>
                            <ScienceClubSideBar />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default PictureTalk2023;